import { ControllerFlowAPI, IWixAPI } from '@wix/yoshi-flow-editor';
import { CouponNames } from '@wix/loyalty-coupon-names';

import { RootState } from '../components/MyRewards/Widget/store/rootReducer';

export type Extra = {
  flowAPI: ControllerFlowAPI;
  wixCodeApi: IWixAPI;
  couponNames: CouponNames;
};

export type ThunkApiConfig = {
  state: RootState;
  extra: Extra;
};

export enum RequestStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  IDLE = 'idle',
  LOADING = 'loading',
}
