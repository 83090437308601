import React from 'react';
import { ProgressBar, Text } from 'wix-ui-tpa/cssVars';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { SimpleReward } from '../../../types/domain';
import { classes } from './reward-progress.st.css';
import { DataHook } from '../../../types/data-hook';
import { useAccount, useLoyaltyProgram } from './hooks/context-hooks';

type RewardProgressProps = {
  reward: SimpleReward;
};

export const RewardProgress = ({ reward }: RewardProgressProps) => {
  const { t } = useTranslation();
  const { program } = useLoyaltyProgram();
  const { account } = useAccount();

  return (
    <div data-hook={DataHook.RewardsListItemProgressBar} className={classes.root}>
      <div className={classes.progressBarContainer}>
        <ProgressBar
          value={Math.floor(((account.pointsBalance ?? 0) / (reward.requiredPoints ?? 1)) * 100)}
          className={classes.progressBar}
        />
      </div>
      <Text data-hook={DataHook.RewardProgressPointsNeeded} className={classes.pointsNeeded}>
        {t(
          program.pointDefinition?.customName
            ? 'app.reward-progress.points-needed-custom'
            : 'app.reward-progress.points-needed',
          {
            pointsName: program.pointDefinition?.customName,
            pointsNeeded: (reward.requiredPoints ?? 0) - (account.pointsBalance ?? 0),
          },
        )}
      </Text>
    </div>
  );
};
